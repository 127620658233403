import { ReactNode } from 'react';

import { DatetimeShow } from '@fhs/ui';

import {
  captainsClubEndDate,
  captainsClubStartDate,
  poweradeVictoryBundleEndDate,
  poweradeVictoryBundleStartDate,
} from '../constants';

export type LtoContentUSProps = {
  poweradeVictoryBundleContent?: ReactNode;
  captainsClubContent?: ReactNode;
};

export const LtoContentUS = ({
  captainsClubContent,
  poweradeVictoryBundleContent,
}: LtoContentUSProps) => {
  return (
    <>
      <DatetimeShow after={poweradeVictoryBundleStartDate} before={poweradeVictoryBundleEndDate}>
        {poweradeVictoryBundleContent}
      </DatetimeShow>
      <DatetimeShow after={captainsClubStartDate} before={captainsClubEndDate}>
        {captainsClubContent}
      </DatetimeShow>
    </>
  );
};
