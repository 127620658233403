import { ReactNode } from 'react';

import { DatetimeShow } from '@fhs/ui';

import { thanksgivingTurkeySubEndDate, thanksgivingTurkeySubStartDate } from '../constants';

export type LtoContentCAProps = {
  thanksgivingTurkeySub?: ReactNode;
  default?: ReactNode;
};

export const LtoContentCA = ({
  default: defaultContent,
  thanksgivingTurkeySub,
}: LtoContentCAProps) => {
  return (
    <>
      <DatetimeShow before={thanksgivingTurkeySubStartDate}>{defaultContent}</DatetimeShow>
      <DatetimeShow after={thanksgivingTurkeySubStartDate} before={thanksgivingTurkeySubEndDate}>
        {thanksgivingTurkeySub}
      </DatetimeShow>
    </>
  );
};
