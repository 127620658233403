import { Image, ImageProps } from 'expo-image';
import { StyleSheet } from 'react-native';

import { LtoContentCA } from '../lto-content/lto-content-ca';

export type TopMarketingImageProps = Omit<ImageProps, 'children' | 'source'>;
export function TopMarketingImage(props: TopMarketingImageProps) {
  return (
    <LtoContentCA
      default={
        <Image
          source={require('./top-marketing-image-ca.webp')}
          placeholder={{ thumbhash: '3mmGDQImhnmQeXTZp3OwQykNN1iCeXXoCA' }}
          alt="Canadian Smoked Turkey. On The NEW Smoked Turkey Italian Sub"
          contentFit="contain"
          {...props}
          style={[styles.marketingImage, props.style]}
        />
      }
      thanksgivingTurkeySub={
        <Image
          source={require('./thanksgiving-turkey-sub.webp')}
          placeholder={{ thumbhash: 'HoqGDQImeUJwp2WJqClg4uUFCIeEiWeoiA' }}
          alt="It's back! Thanksgiving Turkey Sub"
          contentFit="contain"
          {...props}
          style={[styles.marketingImage, props.style]}
        />
      }
    />
  );
}

const styles = StyleSheet.create({
  marketingImage: {
    aspectRatio: 1600 / 1622,
    width: '100%',
    height: '100%',
    minHeight: 200,
    alignSelf: 'center',
  },
});
